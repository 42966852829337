import { BaseDto } from "@/shared/dtos/base-dto";

export class DashBoardGeneralDto extends BaseDto {
  public incidencias_asignadas_activas!: number;
  public tareas_asignadas_activas!: number;
  public proyectos_asignadas_activas!: number;

  public incidencias_asignadas_archivadas!: number;
  public tareas_asignadas_archivadas!: number;
  public proyectos_asignadas_archivadas!: number;

  public incidencias_asignadas_vencidas!: number;
  public tareas_asignadas_vencidas!: number;
  public proyectos_asignadas_vencidas!: number;

  public incidencias_asignadas_vencen_este_mes!: number;
  public tareas_asignadas_vencen_este_mes!: number;
  public proyectos_asignadas_vencen_este_mes!: number;

  public incidencias_asignadas_vencen_esta_semana!: number;
  public tareas_asignadas_vencen_esta_semana!: number;
  public proyectos_asignadas_vencen_esta_semana!: number;

  public graf_incidencias_asignadas!: [];
  public graf_tareas_asignadas!: [];
  public graf_proyectos_asignadas!:[];

  public graf_general!:[];
}
