import { store } from "@/store/store";
import { DashBoardGeneralDto } from "@/shared/dtos/dashboards/DashBoardGeneralDto";
import {
  VuexModule,
  Mutation,
  getModule,
  Module,
  Action,
} from "vuex-module-decorators";
import { ssmHttpService } from "@/shared/services/http-service";
import { API } from "@/shared/api";

@Module({
  namespaced: true,
  name: "DashBoardModule",
  store,
  dynamic: true,
})
class DashBoardModule extends VuexModule {
  public DashBoard: DashBoardGeneralDto = new DashBoardGeneralDto();

  @Action({ commit: "onGetDashBoard" })
  public async getDashBoards() {
    return await ssmHttpService.get(API.DashBoard);
  }

  @Mutation
  public onGetDashBoard(res: DashBoardGeneralDto) {
    this.DashBoard = res;
  }
}
export default getModule(DashBoardModule);
